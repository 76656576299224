"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PlayerChangePin = void 0;
var constants_1 = require("./constants");
var validators_1 = require("../utils/validators/validators");
var isBoolean_1 = require("../utils/validators/typechecker/isBoolean");
var isDecimal_1 = require("../utils/validators/string/isDecimal");
var isNotEmpty_1 = require("../utils/validators/common/isNotEmpty");
var length_1 = require("../utils/validators/string/length");
var matches_1 = require("../utils/validators/string/matches");
var PlayerChangePin = /** @class */ (function () {
    function PlayerChangePin(newPin, confirmation, oldPin, hasPassword) {
        this.newPin = newPin;
        this.confirmation = confirmation;
        this.oldPin = oldPin;
        this.hasPassword = hasPassword;
        if (!oldPin || oldPin.length === 0) {
            this.oldPin = undefined;
        }
    }
    PlayerChangePin.build = function (newPin, confirmation, oldPin, hasPassword) {
        return new PlayerChangePin(newPin, confirmation, oldPin, hasPassword);
    };
    PlayerChangePin.tryCreate = function (playerChangePin) {
        return validators_1.validate(playerChangePin)
            .required('newPin', function (newPin, check) {
            check(isNotEmpty_1.isNotEmpty(newPin), constants_1.PLAYER_CHANGE_PIN_ERROR_MESSAGE);
            check(isDecimal_1.isDecimal(newPin), constants_1.PLAYER_CHANGE_PIN_FORMAT_ERROR_MESSAGE);
            check(length_1.length(newPin, constants_1.PLAYER_CHANGE_PIN_MIN_LENGTH, constants_1.PLAYER_CHANGE_PIN_MAX_LENGTH), constants_1.PLAYER_CHANGE_PIN_ERROR_MESSAGE);
        })
            .required('confirmation', function (confirmation, check) {
            check(isNotEmpty_1.isNotEmpty(confirmation), constants_1.PLAYER_CHANGE_PIN_ERROR_MESSAGE);
            check(isDecimal_1.isDecimal(confirmation), constants_1.PLAYER_CHANGE_PIN_FORMAT_ERROR_MESSAGE);
            check(length_1.length(confirmation, constants_1.PLAYER_CHANGE_PIN_MIN_LENGTH, constants_1.PLAYER_CHANGE_PIN_MAX_LENGTH), constants_1.PLAYER_CHANGE_PIN_ERROR_MESSAGE);
            check(matches_1.matches(confirmation, playerChangePin.newPin), constants_1.PLAYER_CHANGE_PIN_INCORRECT_CONFIRMATION_ERROR_MESSAGE);
        })
            .required('hasPassword', function (hasPassword, check) {
            check(isNotEmpty_1.isNotEmpty(hasPassword), 'Что-то пошло не так!');
            check(isBoolean_1.isBoolean(hasPassword), 'Что-то пошло не так!');
        })
            .requiredIf('oldPin', !!playerChangePin.hasPassword || !!playerChangePin.oldPin, function (oldPin, check) {
            check(isNotEmpty_1.isNotEmpty(oldPin), constants_1.PLAYER_CHANGE_PIN_ERROR_MESSAGE);
            check(isDecimal_1.isDecimal(oldPin), constants_1.PLAYER_CHANGE_PIN_FORMAT_ERROR_MESSAGE);
            check(length_1.length(oldPin, constants_1.PLAYER_CHANGE_PIN_MIN_LENGTH, constants_1.PLAYER_CHANGE_PIN_MAX_LENGTH), constants_1.PLAYER_CHANGE_PIN_ERROR_MESSAGE);
        })
            .tryToCreate(function (value) {
            return new PlayerChangePin(value.newPin, value.confirmation, value.oldPin, value.hasPassword);
        });
    };
    return PlayerChangePin;
}());
exports.PlayerChangePin = PlayerChangePin;
