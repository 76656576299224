"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Game = void 0;
var constants_1 = require("./constants");
var GameTypes_1 = require("./GameTypes");
var validators_1 = require("../utils/validators/validators");
var PlayerShortInfo_1 = require("./PlayerShortInfo");
var isBoolean_1 = require("../utils/validators/typechecker/isBoolean");
var isEnum_1 = require("../utils/validators/typechecker/isEnum");
var isNotEmpty_1 = require("../utils/validators/common/isNotEmpty");
var isString_1 = require("../utils/validators/typechecker/isString");
var length_1 = require("../utils/validators/string/length");
var Game = /** @class */ (function () {
    function Game(id, registrationId, state, hasPassword, title, description, players) {
        this.id = id;
        this.registrationId = registrationId;
        this.state = state;
        this.hasPassword = hasPassword;
        this.title = title;
        this.description = description;
        this.players = players;
    }
    Game.prototype.applyChanges = function (changes) {
        if ('title' in changes) {
            this.title = changes.title.value;
        }
        if ('description' in changes) {
            this.description = changes.description.value;
        }
        if ('state' in changes) {
            this.state = changes.state.value;
        }
    };
    Game.tryCreate = function (game) {
        return validators_1.validate(game)
            .required('id', function (id, check) {
            check(isNotEmpty_1.isNotEmpty(id), 'id is empty');
            check(isString_1.isString(id), 'id is not a string');
        })
            .required('registrationId', function (registrationId, check) {
            check(isNotEmpty_1.isNotEmpty(registrationId), 'registrationId is empty');
            check(isString_1.isString(registrationId), 'registrationId is not a string');
        })
            .required('state', function (state, check) {
            check(isNotEmpty_1.isNotEmpty(state), 'state is empty');
            check(isEnum_1.isEnum(state, GameTypes_1.GameState), 'state is not a GameState');
        })
            .required('hasPassword', function (hasPassword, check) {
            check(isNotEmpty_1.isNotEmpty(hasPassword), 'hasPassword is empty');
            check(isBoolean_1.isBoolean(hasPassword), 'hasPassword is not a boolean');
        })
            .optional('title', function (title, check) {
            check(isString_1.isString(title), constants_1.GAME_TITLE_ERROR_MESSAGE);
            check(length_1.length(title.trim(), constants_1.GAME_TITLE_MIN_LENGTH, constants_1.GAME_TITLE_MAX_LENGTH), constants_1.GAME_TITLE_ERROR_MESSAGE);
        })
            .optional('description', function (description, check) {
            check(isString_1.isString(description), constants_1.GAME_DESCRIPTION_ERROR_MESSAGE);
            check(length_1.length(description.trim(), constants_1.GAME_DESCRIPTION_MIN_LENGTH, constants_1.GAME_DESCRIPTION_MAX_LENGTH), constants_1.GAME_DESCRIPTION_ERROR_MESSAGE);
        })
            .optional('players', function (players, check, setResult) {
            var _a = validators_1.isValidNestedArray(players, PlayerShortInfo_1.PlayerShortInfo.tryCreate), result = _a[0], valid = _a[1];
            check(valid, 'players are not valid');
            setResult(result);
        })
            .tryToCreate(function (value) {
            return new Game(value.id, value.registrationId, value.state, value.hasPassword, value.title, value.description, value.players);
        });
    };
    return Game;
}());
exports.Game = Game;
