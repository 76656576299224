"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PlayerChange = void 0;
var PlayerChange = /** @class */ (function () {
    function PlayerChange(player, changes) {
        this.player = player;
        this.changes = changes;
    }
    return PlayerChange;
}());
exports.PlayerChange = PlayerChange;
