"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GameChange = void 0;
var GameChange = /** @class */ (function () {
    function GameChange(game, changes) {
        this.game = game;
        this.changes = changes;
    }
    return GameChange;
}());
exports.GameChange = GameChange;
