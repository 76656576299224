"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PLAYER_CHANGE_PIN_INCORRECT_CONFIRMATION_ERROR_MESSAGE = exports.PLAYER_CHANGE_PIN_ERROR_MESSAGE = exports.PLAYER_CHANGE_PIN_FORMAT_ERROR_MESSAGE = exports.PLAYER_CHANGE_PIN_MAX_LENGTH = exports.PLAYER_CHANGE_PIN_MIN_LENGTH = exports.PLAYER_TABOO_ERROR_MESSAGE = exports.PLAYER_TABOO_MAX_LENGTH = exports.PLAYER_TABOO_MIN_LENGTH = exports.PLAYER_WISH_ERROR_MESSAGE = exports.PLAYER_WISH_MAX_LENGTH = exports.PLAYER_WISH_MIN_LENGTH = exports.PLAYER_NAME_ERROR_MESSAGE = exports.PLAYER_NAME_MAX_LENGTH = exports.PLAYER_NAME_MIN_LENGTH = void 0;
exports.PLAYER_NAME_MIN_LENGTH = 5;
exports.PLAYER_NAME_MAX_LENGTH = 255;
exports.PLAYER_NAME_ERROR_MESSAGE = "\u041F\u043E\u043B\u0435 '\u0418\u043C\u044F \u0438 \u0424\u0430\u043C\u0438\u043B\u0438\u044F' \u0434\u043E\u043B\u0436\u043D\u043E \u0437\u0430\u043D\u0438\u043C\u0430\u0442\u044C \u043E\u0442 " + exports.PLAYER_NAME_MIN_LENGTH + " \u0434\u043E " + exports.PLAYER_NAME_MAX_LENGTH + " \u0441\u0438\u043C\u0432\u043E\u043B\u043E\u0432";
exports.PLAYER_WISH_MIN_LENGTH = 0;
exports.PLAYER_WISH_MAX_LENGTH = 1000;
exports.PLAYER_WISH_ERROR_MESSAGE = "\u041F\u043E\u043B\u0435 '\u041F\u043E\u0436\u0435\u043B\u0430\u043D\u0438\u044F' \u0434\u043E\u043B\u0436\u043D\u043E \u0437\u0430\u043D\u0438\u043C\u0430\u0442\u044C \u043D\u0435 \u0431\u043E\u043B\u0435\u0435 " + exports.PLAYER_WISH_MAX_LENGTH + " \u0441\u0438\u043C\u0432\u043E\u043B\u043E\u0432";
exports.PLAYER_TABOO_MIN_LENGTH = 0;
exports.PLAYER_TABOO_MAX_LENGTH = 1000;
exports.PLAYER_TABOO_ERROR_MESSAGE = "\u041F\u043E\u043B\u0435 '\u041D\u0435 \u0434\u0430\u0440\u0438\u0442\u044C \u043D\u0438 \u0432 \u043A\u043E\u0435\u043C \u0441\u043B\u0443\u0447\u0430\u0435' \u0434\u043E\u043B\u0436\u043D\u043E \u0437\u0430\u043D\u0438\u043C\u0430\u0442\u044C \u043D\u0435 \u0431\u043E\u043B\u0435\u0435 " + exports.PLAYER_WISH_MAX_LENGTH + " \u0441\u0438\u043C\u0432\u043E\u043B\u043E\u0432";
exports.PLAYER_CHANGE_PIN_MIN_LENGTH = 4;
exports.PLAYER_CHANGE_PIN_MAX_LENGTH = 8;
exports.PLAYER_CHANGE_PIN_FORMAT_ERROR_MESSAGE = "\u041F\u0438\u043D-\u043A\u043E\u0434 \u0434\u043E\u043B\u0436\u0435\u043D \u0441\u043E\u0434\u0435\u0440\u0436\u0430\u0442\u044C \u0442\u043E\u043B\u044C\u043A\u043E \u0446\u0438\u0444\u0440\u044B";
exports.PLAYER_CHANGE_PIN_ERROR_MESSAGE = "\u041F\u0438\u043D-\u043A\u043E\u0434 \u0434\u043E\u043B\u0436\u0435\u043D \u0441\u043E\u0434\u0435\u0440\u0436\u0430\u0442\u044C \u043E\u0442 " + exports.PLAYER_CHANGE_PIN_MIN_LENGTH + " \u0434\u043E " + exports.PLAYER_CHANGE_PIN_MAX_LENGTH + " \u0441\u0438\u043C\u0432\u043E\u043B\u043E\u0432";
exports.PLAYER_CHANGE_PIN_INCORRECT_CONFIRMATION_ERROR_MESSAGE = 'Пин-коды не совпадают';
