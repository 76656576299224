"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PlayerShortInfo = void 0;
var constants_1 = require("../player/constants");
var PlayerTypes_1 = require("../player/PlayerTypes");
var validators_1 = require("../utils/validators/validators");
var isEnum_1 = require("../utils/validators/typechecker/isEnum");
var isNotEmpty_1 = require("../utils/validators/common/isNotEmpty");
var isString_1 = require("../utils/validators/typechecker/isString");
var length_1 = require("../utils/validators/string/length");
var PlayerShortInfo = /** @class */ (function () {
    function PlayerShortInfo(publicId, state, name) {
        this.publicId = publicId;
        this.state = state;
        this.name = name;
    }
    PlayerShortInfo.tryCreate = function (playerShortInfo) {
        return validators_1.validate(playerShortInfo)
            .required('publicId', function (publicId, check) {
            check(isNotEmpty_1.isNotEmpty(publicId), 'publicId is empty');
        })
            .required('state', function (state, check) {
            check(isNotEmpty_1.isNotEmpty(state), 'state is empty');
            check(isEnum_1.isEnum(state, PlayerTypes_1.PlayerState), 'state is not a PlayerState');
        })
            .optional('name', function (name, check) {
            check(isString_1.isString(name), constants_1.PLAYER_NAME_ERROR_MESSAGE);
            check(length_1.length(name, constants_1.PLAYER_NAME_MIN_LENGTH, constants_1.PLAYER_NAME_MAX_LENGTH), constants_1.PLAYER_NAME_ERROR_MESSAGE);
        })
            .tryToCreate(function (value) {
            return new PlayerShortInfo(value.publicId, value.state, value.name);
        });
    };
    return PlayerShortInfo;
}());
exports.PlayerShortInfo = PlayerShortInfo;
