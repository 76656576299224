"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.validate = exports.isValidNestedArray = exports.isValidNested = void 0;
var isArray_1 = require("./typechecker/isArray");
var isDefined_1 = require("./common/isDefined");
var getChecker = function () {
    var firstError = true;
    var validationMessages = [];
    var check = function (condition, message) {
        if (firstError && !condition) {
            validationMessages.push(message);
            firstError = false;
        }
    };
    return [check, validationMessages];
};
var getResult = function (value) {
    var initValue = value;
    var result;
    var getter = function () {
        return result !== null && result !== void 0 ? result : initValue;
    };
    var setter = function (value) {
        result = value;
    };
    return [getter, setter];
};
var mapValidationErrors = function (field, validationMessages) {
    return validationMessages.map(function (message) { return ({
        field: field,
        message: message,
    }); });
};
var isValidNested = function (value, transformer) {
    var _a = transformer(value), x = _a[0], errors = _a[1];
    if (errors.length > 0 || !x) {
        return [undefined, false];
    }
    return [x, true];
};
exports.isValidNested = isValidNested;
var isValidNestedArray = function (value, transformer) {
    if (!isArray_1.isArray(value)) {
        return [undefined, false];
    }
    var result = [];
    for (var _i = 0, value_1 = value; _i < value_1.length; _i++) {
        var item = value_1[_i];
        var _a = transformer(item), x = _a[0], errors = _a[1];
        if (errors.length > 0 || !x) {
            return [undefined, false];
        }
        result.push(x);
    }
    return [result, true];
};
exports.isValidNestedArray = isValidNestedArray;
var validate = function (object) {
    var validationErrors = [];
    var resultObject = {};
    var result = {
        required: function (field, validate) {
            var value = object[field];
            var _a = getChecker(), check = _a[0], validationMessages = _a[1];
            var _b = getResult(value), getter = _b[0], setter = _b[1];
            validate(value, check, setter);
            validationErrors.push.apply(validationErrors, mapValidationErrors(field, validationMessages));
            resultObject[field] = getter();
            return result;
        },
        requiredIf: function (field, condition, validate) {
            var value = object[field];
            var _a = getChecker(), check = _a[0], validationMessages = _a[1];
            var _b = getResult(value), getter = _b[0], setter = _b[1];
            if (condition) {
                validate(value, check, setter);
                validationErrors.push.apply(validationErrors, mapValidationErrors(field, validationMessages));
                resultObject[field] = getter();
            }
            return result;
        },
        optional: function (field, validate) {
            var value = object[field];
            var _a = getChecker(), check = _a[0], validationMessages = _a[1];
            var _b = getResult(value), getter = _b[0], setter = _b[1];
            if (isDefined_1.isDefined(value)) {
                validate(value, check, setter);
                validationErrors.push.apply(validationErrors, mapValidationErrors(field, validationMessages));
                resultObject[field] = getter();
            }
            return result;
        },
        tryToCreate: function (transformer) {
            return validationErrors.length === 0
                ? [transformer(resultObject), []]
                : [undefined, validationErrors];
        },
    };
    return result;
};
exports.validate = validate;
