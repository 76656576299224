"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Target = void 0;
var constants_1 = require("./constants");
var PlayerTypes_1 = require("./PlayerTypes");
var validators_1 = require("../utils/validators/validators");
var isEnum_1 = require("../utils/validators/typechecker/isEnum");
var isNotEmpty_1 = require("../utils/validators/common/isNotEmpty");
var isString_1 = require("../utils/validators/typechecker/isString");
var length_1 = require("../utils/validators/string/length");
var Target = /** @class */ (function () {
    function Target(state, name, wish, taboo) {
        this.state = state;
        this.name = name;
        this.wish = wish;
        this.taboo = taboo;
    }
    Target.tryCreate = function (target) {
        return validators_1.validate(target)
            .required('state', function (state, check) {
            check(isNotEmpty_1.isNotEmpty(state), 'state is empty');
            check(isEnum_1.isEnum(state, PlayerTypes_1.PlayerState), 'state is not a PlayerState');
        })
            .optional('name', function (name, check) {
            check(isString_1.isString(name), constants_1.PLAYER_NAME_ERROR_MESSAGE);
            check(length_1.length(name, constants_1.PLAYER_NAME_MIN_LENGTH, constants_1.PLAYER_NAME_MAX_LENGTH), constants_1.PLAYER_NAME_ERROR_MESSAGE);
        })
            .optional('wish', function (wish, check) {
            check(isString_1.isString(wish), constants_1.PLAYER_WISH_ERROR_MESSAGE);
            check(length_1.length(wish, constants_1.PLAYER_WISH_MIN_LENGTH, constants_1.PLAYER_WISH_MAX_LENGTH), constants_1.PLAYER_WISH_ERROR_MESSAGE);
        })
            .optional('taboo', function (taboo, check) {
            check(isString_1.isString(taboo), constants_1.PLAYER_TABOO_ERROR_MESSAGE);
            check(length_1.length(taboo, constants_1.PLAYER_TABOO_MIN_LENGTH, constants_1.PLAYER_TABOO_MAX_LENGTH), constants_1.PLAYER_TABOO_ERROR_MESSAGE);
        })
            .tryToCreate(function (value) {
            return new Target(value.state, value.name, value.wish, value.taboo);
        });
    };
    return Target;
}());
exports.Target = Target;
