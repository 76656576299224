"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Player = void 0;
var constants_1 = require("./constants");
var PlayerTypes_1 = require("./PlayerTypes");
var validators_1 = require("../utils/validators/validators");
var GameShortInfo_1 = require("./GameShortInfo");
var Target_1 = require("./Target");
var isBoolean_1 = require("../utils/validators/typechecker/isBoolean");
var isEnum_1 = require("../utils/validators/typechecker/isEnum");
var isNotEmpty_1 = require("../utils/validators/common/isNotEmpty");
var isString_1 = require("../utils/validators/typechecker/isString");
var length_1 = require("../utils/validators/string/length");
var Player = /** @class */ (function () {
    function Player(id, state, hasPassword, name, wish, taboo, target, game) {
        this.id = id;
        this.state = state;
        this.hasPassword = hasPassword;
        this.name = name;
        this.wish = wish;
        this.taboo = taboo;
        this.target = target;
        this.game = game;
    }
    Player.prototype.applyChanges = function (changes) {
        if ('name' in changes) {
            this.name = changes.name.value;
        }
        if ('wish' in changes) {
            this.wish = changes.wish.value;
        }
        if ('taboo' in changes) {
            this.taboo = changes.taboo.value;
        }
        if ('state' in changes) {
            this.state = changes.state.value;
        }
    };
    Player.tryCreate = function (player) {
        return validators_1.validate(player)
            .required('id', function (id, check) {
            check(isNotEmpty_1.isNotEmpty(id), 'id is empty');
            check(isString_1.isString(id), 'id is not a string');
        })
            .required('state', function (state, check) {
            check(isNotEmpty_1.isNotEmpty(state), 'state is empty');
            check(isEnum_1.isEnum(state, PlayerTypes_1.PlayerState), 'state is not a PlayerState');
        })
            .required('hasPassword', function (hasPassword, check) {
            check(isNotEmpty_1.isNotEmpty(hasPassword), 'hasPassword is empty');
            check(isBoolean_1.isBoolean(hasPassword), 'hasPassword is not a boolean');
        })
            .optional('name', function (name, check) {
            check(isString_1.isString(name), constants_1.PLAYER_NAME_ERROR_MESSAGE);
            check(length_1.length(name.trim(), constants_1.PLAYER_NAME_MIN_LENGTH, constants_1.PLAYER_NAME_MAX_LENGTH), constants_1.PLAYER_NAME_ERROR_MESSAGE);
        })
            .optional('wish', function (wish, check) {
            check(isString_1.isString(wish), constants_1.PLAYER_WISH_ERROR_MESSAGE);
            check(length_1.length(wish.trim(), constants_1.PLAYER_WISH_MIN_LENGTH, constants_1.PLAYER_WISH_MAX_LENGTH), constants_1.PLAYER_WISH_ERROR_MESSAGE);
        })
            .optional('taboo', function (taboo, check) {
            check(isString_1.isString(taboo), constants_1.PLAYER_TABOO_ERROR_MESSAGE);
            check(length_1.length(taboo.trim(), constants_1.PLAYER_TABOO_MIN_LENGTH, constants_1.PLAYER_TABOO_MAX_LENGTH), constants_1.PLAYER_TABOO_ERROR_MESSAGE);
        })
            .optional('target', function (target, check, setResult) {
            var _a = validators_1.isValidNested(target, Target_1.Target.tryCreate), result = _a[0], valid = _a[1];
            check(valid, 'target is not valid');
            setResult(result);
        })
            .optional('game', function (game, check, setResult) {
            var _a = validators_1.isValidNested(game, GameShortInfo_1.GameShortInfo.tryCreate), result = _a[0], valid = _a[1];
            check(valid, 'game is not valid');
            setResult(result);
        })
            .tryToCreate(function (value) {
            return new Player(value.id, value.state, value.hasPassword, value.name, value.wish, value.taboo, value.target, value.game);
        });
    };
    return Player;
}());
exports.Player = Player;
