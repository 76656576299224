"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GAME_CHANGE_PIN_INCORRECT_CONFIRMATION_ERROR_MESSAGE = exports.GAME_CHANGE_PIN_ERROR_MESSAGE = exports.GAME_CHANGE_PIN_FORMAT_ERROR_MESSAGE = exports.GAME_CHANGE_PIN_MAX_LENGTH = exports.GAME_CHANGE_PIN_MIN_LENGTH = exports.GAME_DESCRIPTION_ERROR_MESSAGE = exports.GAME_DESCRIPTION_MAX_LENGTH = exports.GAME_DESCRIPTION_MIN_LENGTH = exports.GAME_TITLE_ERROR_MESSAGE = exports.GAME_TITLE_MAX_LENGTH = exports.GAME_TITLE_MIN_LENGTH = void 0;
exports.GAME_TITLE_MIN_LENGTH = 5;
exports.GAME_TITLE_MAX_LENGTH = 255;
exports.GAME_TITLE_ERROR_MESSAGE = "\u0417\u0430\u0433\u043E\u043B\u043E\u0432\u043E\u043A \u0434\u043E\u043B\u0436\u0435\u043D \u0437\u0430\u043D\u0438\u043C\u0430\u0442\u044C \u043E\u0442 " + exports.GAME_TITLE_MIN_LENGTH + " \u0434\u043E " + exports.GAME_TITLE_MAX_LENGTH + " \u0441\u0438\u043C\u0432\u043E\u043B\u043E\u0432";
exports.GAME_DESCRIPTION_MIN_LENGTH = 5;
exports.GAME_DESCRIPTION_MAX_LENGTH = 1000;
exports.GAME_DESCRIPTION_ERROR_MESSAGE = "\u041E\u043F\u0438\u0441\u0430\u043D\u0438\u0435 \u0434\u043E\u043B\u0436\u043D\u043E \u0437\u0430\u043D\u0438\u043C\u0430\u0442\u044C \u043E\u0442 " + exports.GAME_DESCRIPTION_MIN_LENGTH + " \u0434\u043E " + exports.GAME_DESCRIPTION_MAX_LENGTH + " \u0441\u0438\u043C\u0432\u043E\u043B\u043E\u0432";
exports.GAME_CHANGE_PIN_MIN_LENGTH = 4;
exports.GAME_CHANGE_PIN_MAX_LENGTH = 8;
exports.GAME_CHANGE_PIN_FORMAT_ERROR_MESSAGE = "\u041F\u0438\u043D-\u043A\u043E\u0434 \u0434\u043E\u043B\u0436\u0435\u043D \u0441\u043E\u0434\u0435\u0440\u0436\u0430\u0442\u044C \u0442\u043E\u043B\u044C\u043A\u043E \u0446\u0438\u0444\u0440\u044B";
exports.GAME_CHANGE_PIN_ERROR_MESSAGE = "\u041F\u0438\u043D-\u043A\u043E\u0434 \u0434\u043E\u043B\u0436\u0435\u043D \u0441\u043E\u0434\u0435\u0440\u0436\u0430\u0442\u044C \u043E\u0442 " + exports.GAME_CHANGE_PIN_MIN_LENGTH + " \u0434\u043E " + exports.GAME_CHANGE_PIN_MAX_LENGTH + " \u0441\u0438\u043C\u0432\u043E\u043B\u043E\u0432";
exports.GAME_CHANGE_PIN_INCORRECT_CONFIRMATION_ERROR_MESSAGE = 'Пин-коды не совпадают';
