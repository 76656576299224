"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GameShortInfo = void 0;
var constants_1 = require("../game/constants");
var GameTypes_1 = require("../game/GameTypes");
var validators_1 = require("../utils/validators/validators");
var isEnum_1 = require("../utils/validators/typechecker/isEnum");
var isNotEmpty_1 = require("../utils/validators/common/isNotEmpty");
var isString_1 = require("../utils/validators/typechecker/isString");
var length_1 = require("../utils/validators/string/length");
var GameShortInfo = /** @class */ (function () {
    function GameShortInfo(state, title, description) {
        this.state = state;
        this.title = title;
        this.description = description;
    }
    GameShortInfo.tryCreate = function (gameShortInfo) {
        return validators_1.validate(gameShortInfo)
            .required('state', function (state, check) {
            check(isNotEmpty_1.isNotEmpty(state), 'state is empty');
            check(isEnum_1.isEnum(state, GameTypes_1.GameState), 'state is not a GameState');
        })
            .optional('title', function (title, check) {
            check(isString_1.isString(title), constants_1.GAME_TITLE_ERROR_MESSAGE);
            check(length_1.length(title, constants_1.GAME_TITLE_MIN_LENGTH, constants_1.GAME_TITLE_MAX_LENGTH), constants_1.GAME_TITLE_ERROR_MESSAGE);
        })
            .optional('description', function (description, check) {
            check(isString_1.isString(description), constants_1.GAME_DESCRIPTION_ERROR_MESSAGE);
            check(length_1.length(description, constants_1.GAME_DESCRIPTION_MIN_LENGTH, constants_1.GAME_DESCRIPTION_MAX_LENGTH), constants_1.GAME_DESCRIPTION_ERROR_MESSAGE);
        })
            .tryToCreate(function (value) {
            return new GameShortInfo(value.state, value.title, value.description);
        });
    };
    return GameShortInfo;
}());
exports.GameShortInfo = GameShortInfo;
